<template>
  <div style="width: 100%">
    <div>
      <div class="affiche-top">
        <span class="navigation-btn" @click="$router.push('/')">首页</span>
        <div style="display: flex; align-items: center">
          <img class="img-right" src="@/assets/images/right-arrow.png" alt="" />
          <span class="title1">{{ title }}</span>
        </div>
      </div>
    </div>
    <div class="guide-page" ref="boxs">
      <!-- 这是指引路 由 页面 -->

      <div class="main-content">
        <!-- 左边框 -->
        <div class="left-frame">
          <!-- 侧边导航 -->
          <div
            class="lest-item"
            v-for="(item, index) in guideTypeList"
            :key="index"
            :class="{
              'guide-active': currentId == item.bzId,
            }"
            @click="switchList(item.bzId, item.bzTitle)"
          >
            {{ item.bzTitle }}
          </div>
        </div>
        <!-- 右边指引详情 -->
        <div class="right-content">
          <!-- 右边内容 -->
          <div class="title-box">
            <div class="title">{{ title }}</div>
          </div>
          <div v-html="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 导入封装的请求方法文件
import common from "../../api/common";
export default {
  name: "guidePage",
  data() {
    return {
      // 侧边栏移上高亮下标
      currentId: NaN,
      // 右边标题
      title: "网上报名流程指引",
      content: "",
      // 侧边点击切换栏列表
      guideTypeList: [],
    };
  },
  watch: {
    $route: {
      deep: true,
      handler(newValue) {
        // console.log(newValue, "newValue新来的新来的");
        this.currentId = newValue.params.id;
        this.getGuideTypeList();
      },
    },
  },
  created() {
    this.currentId = this.$route.params.id;
    // console.log(this.$route.params.id, "this.$route.params.id");
    this.getGuideTypeList();
  },

  methods: {
    // 点击
    switchList(b, text) {
      console.log(text, "text");
      this.noticeIndex = b;
      this.title = text;
      this.currentId = b;
      for (var i = 0; i < this.guideTypeList.length; i++) {
        if (this.currentId == this.guideTypeList[i].bzId) {
          this.content = this.guideTypeList[i].bzCentont;
        }
      }
    },

    // 获取指引列表
    getGuideTypeList() {
      common.getGuideList().then((res) => {
        // console.log(res.rows);
        // this.guideList =
        this.guideTypeList = res.rows;
        for (var i = 0; i < this.guideTypeList.length; i++) {
          if (this.currentId == this.guideTypeList[i].bzId) {
            this.content = this.guideTypeList[i].bzCentont;
            this.title = this.guideTypeList[i].bzTitle;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.guide-page {
  width: 100%;
  min-height: 65vh;
}

.main-content {
  width: 100%;
  /* height: 300px; */
  display: flex;
  margin-top: 26px;
}

.left-frame {
  width: 300px;
  color: #000000;
  margin-right: 50px;
  background: #ffffff;
  padding: 16px 25px;
}

.lest-item {
  color: #333333;
  font-size: 14px;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.right-content {
  flex: 1;
  background: #ffffff;
  padding: 20px 40px;
  overflow: hidden;
}

.guide-active {
  color: #ffffff;
  background: #e01811;
}

.title-box {
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  color: #555;
  font-size: 28px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
n {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0px 12px;
}
.affiche-top {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 16px 0px 0 0;
  cursor: pointer;
}

.navigation-btn {
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #999999;
}
.img-right {
  width: 20px;
  height: 25px;
  margin: 0 8px;
}
.title1 {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #e01811;
}
</style>